<template>
  <confirm-dialog
    ref="editBookmarkDescriptionDialog"
    v-model="editBookmarkDescriptionDialog"
    title="メモを変更"
    confirm-label="変更する"
    message="この内容でメモを変更します"
    :disabled="
      bookmarkToEditDescription === null ||
      bookmarkToEditDescription.description === editingBookmarkDescription ||
      descriptionValidator(editingBookmarkDescription) !== true
    "
    @confirm="updateBookmarkDescription"
  >
    <div v-if="bookmarkToEditDescription" class="webview-dialog-body">
      <p>
        文献名 {{ bookmarkToEditDescription.doc.title }}<br />
        {{ bookmarkToEditDescription.sectionTitle }}
      </p>
    </div>
    <v-textarea v-model="editingBookmarkDescription" label="メモ" outlined :rules="[descriptionValidator]" />
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { descriptionValidator } from '@/utils/binderFoldersUtils';
import { BookmarkWithBibliographyWithType } from '@/types/binder-folders';

@Component({
  components: { ConfirmDialog },
})
export default class UpdateBookmarkDialog extends Vue {
  $refs!: {
    editBookmarkDescriptionDialog: ConfirmDialog;
  };

  descriptionValidator = descriptionValidator;

  @Prop()
  success?: () => Promise<void>;

  /** 編集対象のブックマーク */
  bookmarkToEditDescription: BookmarkWithBibliographyWithType | null = null;
  get editBookmarkDescriptionDialog(): boolean {
    return this.bookmarkToEditDescription !== null;
  }
  set editBookmarkDescriptionDialog(value) {
    if (!value) {
      this.bookmarkToEditDescription = null;
    }
  }

  editingBookmarkDescription = '';

  show(item: BookmarkWithBibliographyWithType) {
    this.bookmarkToEditDescription = item;
    this.editingBookmarkDescription = item.description;
  }

  async updateBookmarkDescription() {
    if (!this.bookmarkToEditDescription) {
      return;
    }

    const { id, binderId, updatedAt } = this.bookmarkToEditDescription;

    try {
      await this.$repositories.binderFolders.updateBookmark(id, binderId, this.editingBookmarkDescription, updatedAt);
      this.$toast.success(`メモを変更しました`);
      this.editBookmarkDescriptionDialog = false;
      if (this.success) {
        this.success();
      }
    } catch (error) {
      this.$refs.editBookmarkDescriptionDialog.reactivate();
      console.error(error);
      this.$toast.error(`メモの変更に失敗しました`);
    }
  }
}
</script>
