import { Component, Watch } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import BinderBase from '@/components/shared/binder-base';
import { binderNameValidator, descriptionValidator } from '@/utils/binderFoldersUtils';
import ShareDialog from '@/components/dialog/share-dialog.vue';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import ColorSelector from '@/components/common/color-selector.vue';
import UpdateBookmarkDialog from '@/components/dialog/update-bookmark.vue';
import RemoveBookmarkDialog from '@/components/dialog/remove-bookmark.vue';
import WebViewer from '@/components/viewer/web-viewer.vue';
import { parseBinderItemsResponse } from '@/utils/binderFoldersUtils';
import PdfViewer from '@/components/viewer/pdf-viewer.vue';
import { MetaInfo } from 'vue-meta';
import { BinderItem, HighlightWithBibliographyWithType } from '@/types/binder-folders';
import { HighlightColorEnum } from 'wklr-backend-sdk/models';
@Component({
  components: {
    ColorSelector,
    ConfirmDialog,
    ShareDialog,
    UpdateBookmarkDialog,
    RemoveBookmarkDialog,
  },
})
export default class BinderById extends BinderBase {
  $refs!: {
    webViewer: WebViewer;
    pdfViewer: PdfViewer;
    updateBookmarkDialog: UpdateBookmarkDialog;
    removeBookmarkDialog: RemoveBookmarkDialog;
    'edit-binder-name-dialog': ConfirmDialog;
    'edit-binder-color-dialog': ConfirmDialog;
    'edit-highlight-description-dialog': ConfirmDialog;
    'share-dialog': ShareDialog;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  binderNameValidator = binderNameValidator;
  descriptionValidator = descriptionValidator;

  /** バインダー名編集モーダル表示用のモデル */
  editBinderNameDialog = false;

  head(): MetaInfo {
    return {
      title: this.binder ? `${this.binder.name}のバインダー` : '',
    };
  }

  async asyncData({
    app: { $repositories, $auth },
    error,
    route,
  }: Context): Promise<Pick<BinderById, 'binder' | 'folder' | 'id' | 'items'> | undefined> {
    if (!$auth.permissions.binder) {
      error({ statusCode: 403, message: 'このページは閲覧できません' });
    } else {
      const id = route.params.id;
      try {
        const { binder, folder, items } = parseBinderItemsResponse(
          await $repositories.binderFolders.getBinderItems(id),
        );
        return { binder, folder, id, items };
      } catch (error) {
        console.error(error);
        throw new Error('一覧データの読み込みに失敗しました');
      }
    }
  }

  async updateBinderItems(): Promise<void> {
    if (this.$repositories.binderFolders === undefined) return;
    try {
      const { binder, folder, items } = parseBinderItemsResponse(
        await this.$repositories.binderFolders.getBinderItems(this.id),
      );

      this.binder = binder;
      this.folder = folder;
      this.items = items;
    } catch (error) {
      console.error(error);
      this.$toast.error('一覧データの取得に失敗しました。リロードしてください');
    }
  }

  changeDocumentBinderItemsHandler(): void {
    this.updateBinderItems();
  }

  @Watch('editBinderNameDialog')
  editBinderNameDialogChangeHandler(value: boolean): void {
    if (!value) {
      this.editingBinderName = '';
    }
  }

  /** バインダー名変更用のモデル */
  editingBinderName = '';

  /** バインダー色編集モーダル表示用のモデル */
  editBinderHighlightColorDialog = false;

  @Watch('editBinderHighlightColorDialog')
  editBinderHighlightColorDialogChangeHandler(value: boolean): void {
    if (!value) {
      this.editingBinderColor = null;
    }
  }

  /** バインダー色変更用のモデル */
  editingBinderColor: HighlightColorEnum | null = null;

  /** バインダー削除モーダル表示用のモデル */
  deleteBinderDialog = false;

  /** 削除対象のハイライト */
  highlightToDelete: HighlightWithBibliographyWithType | null = null;

  get deleteHighlightDialog(): boolean {
    return this.highlightToDelete !== null;
  }
  set deleteHighlightDialog(value: boolean) {
    if (!value) {
      this.highlightToDelete = null;
    }
    console.warn('set highlightToDelete to activate deleteHighlightDialog');
  }

  /** 編集対象のハイライト */
  highlightToEditDescription: HighlightWithBibliographyWithType | null = null;
  get editHighlightDescriptionDialog(): boolean {
    return this.highlightToEditDescription !== null;
  }
  set editHighlightDescriptionDialog(value: boolean) {
    if (!value) {
      this.highlightToEditDescription = null;
    }
    console.warn('set highlightToEditDescription to activate editHighlightDescriptionDialog');
  }

  /** ハイライトのメモの更新用データモデル */
  editingHighlightDescription = '';

  openShareBinderDialog(): void {
    this.$refs['share-dialog'].show(this.binder);
  }

  openDeleteBinderDialog(): void {
    this.deleteBinderDialog = true;
  }

  openEditBinderNameDialog(): void {
    this.editBinderNameDialog = true;
    this.editingBinderName = this.binder.name;
  }

  openEditBinderColorDialog(): void {
    this.editBinderHighlightColorDialog = true;
    this.editingBinderColor = this.binder.highlightColor;
  }

  async removeBinder(): Promise<void> {
    try {
      await this.$repositories.binderFolders.removeBinder(this.id, this.binder.updatedAt);
      this.$router.push('/collections');
      this.$toast.success('バインダーを削除しました');
    } catch (error) {
      console.error(error);
      this.$toast.error('バインダーの削除に失敗しました');
    } finally {
      this.deleteBinderDialog = false;
    }
  }

  async updateBinderName(newName: string): Promise<void> {
    try {
      const description = ''; // TODO: バインダーメモ機能をフロントで実装するタイミングで適用する
      const response = await this.$repositories.binderFolders.editBinder(
        this.id,
        this.binder.folderId,
        newName,
        description,
        this.binder.highlightColor,
        this.binder.updatedAt,
      );
      this.binder = response;
      this.editBinderNameDialog = false;
      this.$toast.success('バインダー名を変更しました');
    } catch (error) {
      this.$refs['edit-binder-name-dialog'].reactivate();
      console.error(error);
      this.$toast.error('バインダー名の変更に失敗しました');
    }
  }

  async updateBinderColor(newColor: HighlightColorEnum | null): Promise<void> {
    if (newColor === null) return;
    try {
      const description = ''; // TODO: バインダーメモ機能をフロントで実装するタイミングで適用する
      const response = await this.$repositories.binderFolders.editBinder(
        this.id,
        this.binder.folderId,
        this.binder.name,
        description,
        newColor,
        this.binder.updatedAt,
      );
      this.binder = response;
      this.editBinderHighlightColorDialog = false;
      this.$toast.success('バインダーの色を変更しました');
    } catch (error) {
      this.$refs['edit-binder-color-dialog'].reactivate();
      console.error(error);
      this.$toast.error('バインダーの色の変更に失敗しました');
    }
  }

  shareItemHandler(item: BinderItem): void {
    this.$refs['share-dialog'].show(item);
  }

  editItemHandler(item: BinderItem): void {
    switch (item.type) {
      case 'bookmark':
        this.$refs['updateBookmarkDialog'].show(item);
        break;
      case 'highlight':
        this.highlightToEditDescription = item;
        this.editingHighlightDescription = item.description;
        break;
    }
  }

  /**
   * メモが更新できない状態である場合に true を返します。
   */
  cannotUpdateDescription(
    binderItemToEditDescription: { description: string } | null,
    editingDescription: string,
  ): boolean {
    return (
      binderItemToEditDescription === null ||
      binderItemToEditDescription.description === editingDescription ||
      descriptionValidator(editingDescription) !== true
    );
  }

  async updateHighlightDescription(item: HighlightWithBibliographyWithType | null, description: string): Promise<void> {
    if (!item) return;
    try {
      await this.$repositories.binderFolders.updateHighlight(item.id, item.binderId, description, item.updatedAt);
      this.$toast.success('メモを変更しました');
      this.editHighlightDescriptionDialog = false;
      await this.updateBinderItems();
    } catch (error) {
      this.$refs['edit-highlight-description-dialog'].reactivate();
      console.error(error);
      this.$toast.error('メモの変更に失敗しました');
    }
  }

  async deleteItemHandler(item: BinderItem): Promise<void> {
    switch (item.type) {
      case 'bookmark':
        this.$refs.removeBookmarkDialog.show(item);
        break;
      case 'highlight':
        this.highlightToDelete = item;
        break;
    }
  }

  async removeHighlight(): Promise<void> {
    if (this.highlightToDelete == null) return;
    try {
      await this.$repositories.binderFolders.removeHighlight(
        this.highlightToDelete.id,
        this.highlightToDelete.updatedAt,
      );
      await this.updateBinderItems();
      this.$toast.success('削除しました');
    } catch (error) {
      console.error(error);
      this.$toast.error('削除に失敗しました');
    } finally {
      this.deleteHighlightDialog = false;
    }
  }
}
