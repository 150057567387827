<template>
  <alert-dialog
    v-model="model"
    width="580"
    title="共有"
    label="閉じる"
    message="URLをコピーしてメールなどで共有してください"
    icon="mdi-share-variant"
  >
    <p v-if="detail">
      {{ detail }}
    </p>
    <section class="share-box">
      <v-text-field outlined readonly :value="shareUrl" class="shareInput" @focus="focusInputHandler" />
      <v-btn v-if="success" class="shareButton" color="primary" elevation="0" width="120" @click="copy">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn v-else class="shareButton" color="primary" elevation="0" width="120" @click="copy">コピー</v-btn>
    </section>
  </alert-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import AlertDialog from '@/components/common/alert-dialog.vue';
import { BinderItem } from '@/types/binder-folders';
import { Binder } from 'wklr-backend-sdk/models';

@Component({ components: { AlertDialog } })
export default class ShareDialog extends Vue {
  sharedItem: BinderItem | Binder | null = null;

  success = false;

  get detail(): string | null {
    if (this.sharedItem === null) return null;
    switch (this.sharedItem.type) {
      case 'bookmark':
      case 'highlight':
        return this.sharedItem.headingText;
      default:
        return this.sharedItem.name;
    }
  }

  get shareUrl(): string {
    if (this.sharedItem === null) return '';
    switch (this.sharedItem.type) {
      case 'bookmark':
        return `${location.origin}/share/binder/${this.sharedItem.binderId}/bookmark/${this.sharedItem.id}`;
      case 'highlight':
        return `${location.origin}/share/binder/${this.sharedItem.binderId}/highlight/${this.sharedItem.id}`;
      default:
        return `${location.origin}/share/binder/${this.sharedItem.id}`;
    }
  }

  get model(): boolean {
    return this.sharedItem !== null;
  }
  set model(value) {
    if (value === false) {
      this.sharedItem = null;
      this.success = false;
    }
  }

  focusInputHandler(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement) {
      event.target.select();
    }
  }

  show(item: BinderItem | Binder) {
    this.sharedItem = item;
  }

  async copy() {
    try {
      await navigator.clipboard.writeText(this.shareUrl);
      this.success = true;
    } catch (e) {
      this.$toast.error('URLのコピーに失敗しました');
    }
  }
}
</script>

<style lang="scss">
.share-box {
  display: flex;

  > .shareInput {
    caret-color: $primary;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    // Vuetify のスタイルを上書き
    .v-input__slot {
      padding-left: 22px !important;
      height: 48px !important;
      min-height: 48px !important;

      > fieldset {
        bottom: -1px !important;
      }
    }

    .v-select__slot {
      > input {
        order: 2;
      }

      .v-input__append-inner {
        margin-right: 18px;
        margin-top: 10px;
        padding: 0;
        order: 1;

        > .v-input__icon {
          height: 27px;
          width: 27px;

          > .v-icon {
            transform: none !important;
            font-size: 18px !important;
            height: 18px !important;
            width: 18px !important;
          }
        }
      }
    }

    &.v-text-field--outlined {
      fieldset {
        border: 1px solid #e0e0e0 !important;
      }
    }
    // Vuetify のスタイルを上書き
  }

  > .shareButton {
    margin-top: 1px;
    padding: 0 36px !important;
    height: 48px !important;
    font-weight: bold;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
