<template>
  <confirm-dialog
    v-model="deleteBookmarkDialog"
    title="バインダーから削除"
    confirm-label="削除する"
    @confirm="removeBookmark"
  >
    <!-- TODO: ここにブックマークの詳細情報を表示する -->
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { BookmarkWithBibliographyWithType } from '@/types/binder-folders';

@Component({
  components: { ConfirmDialog },
})
export default class RemoveBookmarkDialog extends Vue {
  @Prop()
  success?: () => Promise<void>;

  bookmarkToDelete: BookmarkWithBibliographyWithType | null = null;
  get deleteBookmarkDialog(): boolean {
    return this.bookmarkToDelete !== null;
  }
  set deleteBookmarkDialog(value) {
    if (!value) {
      this.bookmarkToDelete = null;
    }
  }

  show(item: BookmarkWithBibliographyWithType) {
    this.bookmarkToDelete = item;
  }

  async removeBookmark() {
    if (!this.bookmarkToDelete) {
      return;
    }

    try {
      await this.$repositories.binderFolders.removeBookmark(this.bookmarkToDelete.id, this.bookmarkToDelete.updatedAt);
      if (this.success) {
        this.success();
      }
      this.$toast.success(`削除しました`);
    } catch (error) {
      console.error(error);
      this.$toast.error(`削除に失敗しました`);
    } finally {
      this.deleteBookmarkDialog = false;
    }
  }
}
</script>
